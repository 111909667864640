import React from 'react'
import { graphql, navigate } from 'gatsby'
import {
  Billboard,
  Row,
  Column,
  Wrapper,
  Button,
  H1,
  Modal,
  SEO,
} from '@renderbus/common/components'

import Layout from '../molecules/layout'
import PhoneCombo from '../molecules/phone-combo'
import JoinUsForm from '../molecules/architectural-rendering/join-us-form'
import RegisterBanner from '../molecules/register-banner'
import {
  BannerContainer,
  BannerTitle,
  BannerMessageText,
  BannerMessageCoupon,
  BannerSupportText,
  AdvantageContainer,
  AdvantageTitle,
  LighterBg,
  IconBox,
  IconGiftBox,
  InfoContainer,
  InfoImg,
  InfoContent,
  InfoTitle,
  InfoSubTitle,
  IconInlineBox,
  CompanyIcons,
  CompanyIconBox,
  IconBall,
  AgentBox,
  AgentContent,
  AgentIcon,
  BiggerIconBox,
  BiggerIconTitle,
  BiggerIconContent,
  AgentTitle,
  AgentButton,
  AdvertiseBanner,
} from './architectural-rendering-old.atom'

import playIcon from '../images/architectural-rendering/xgt_play.png'
import playIconHover from '../images/architectural-rendering/xgt_play_hover.png'
import info1 from '../images/architectural-rendering/info1-v5.gif'
import info2 from '../images/architectural-rendering/info2-v5.gif'
import info3 from '../images/architectural-rendering/info3-v5.gif'
import companyIcon1 from '../images/architectural-rendering/max.png'
import companyIcon5 from '../images/architectural-rendering/more.png'
import companyIcon6 from '../images/architectural-rendering/corona.png'
import companyIcon7 from '../images/architectural-rendering/v-ray.png'

import AdvantageIcon1 from '@renderbus/common/components/svg/architectural-01'
import AdvantageIcon2 from '@renderbus/common/components/svg/architectural-02'
import AdvantageIcon3 from '@renderbus/common/components/svg/architectural-03'
import AdvantageIcon4 from '@renderbus/common/components/svg/architectural-04'
import RealTimeIcon1 from '@renderbus/common/components/svg/architectural-05'
import RealTimeIcon2 from '@renderbus/common/components/svg/architectural-06'
import RealTimeIcon3 from '@renderbus/common/components/svg/architectural-07'
import FasterIcon1 from '@renderbus/common/components/svg/architectural-08'
import FasterIcon2 from '@renderbus/common/components/svg/architectural-09'
import FasterIcon3 from '@renderbus/common/components/svg/architectural-10'
import MaterialIcon1 from '@renderbus/common/components/svg/architectural-11'
import MaterialIcon2 from '@renderbus/common/components/svg/architectural-12'
import MaterialIcon3 from '@renderbus/common/components/svg/architectural-13'
import AgentIcon1 from '@renderbus/common/components/svg/architectural-14'
import AgentIcon2 from '@renderbus/common/components/svg/architectural-15'
import AgentIcon3 from '@renderbus/common/components/svg/architectural-16'
import BiggerIcon1 from '@renderbus/common/components/svg/architectural-17'
import BiggerIcon2 from '@renderbus/common/components/svg/architectural-18'
import BiggerIcon3 from '@renderbus/common/components/svg/architectural-19'
import BiggerIcon4 from '@renderbus/common/components/svg/architectural-20'
import { FreeFourIcon, HalfOffIcon, LimitTwenty } from '../molecules/icons'

class ArchitecturalRendering extends React.PureComponent {
  state = {
    playIconSrc: playIcon,
    showModal: false,
    agentType: '',
    showVideo: false,
  }
  videoRef = null
  HoverPlayIcon(isHover) {
    if (isHover) {
      this.setState({ playIconSrc: playIconHover })
    } else {
      this.setState({ playIconSrc: playIcon })
    }
  }
  joinUs(agentType) {
    this.setState({ showModal: true, agentType: agentType })
  }
  CloseModal(event) {
    if (event) event.preventDefault()
    this.setState({ showModal: false })
  }
  ShowVideo() {
    this.setState({ showVideo: true })
    if (this.videoRef) this.videoRef.play()
  }
  CloseVideo(event) {
    if (event) event.preventDefault()
    if (this.videoRef) this.videoRef.pause()
    this.setState({ showVideo: false })
  }

  setVideoRef = ref => {
    this.videoRef = ref
  }
  handleClick(href) {
    window.location.href = href
  }

  clickButton() {
    window.open('https://www.renderbus.com/news/post-id-1141/', '_blank')
  }

  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    const { playIconSrc, showModal, agentType, showVideo } = this.state
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='效果图渲染,一键提交免费渲染_Renderbus自助渲染农场'
          keywords='效果图渲染,免费渲染'
          description='高性价比效果图自助渲染农场,支持CPU自助渲染,不限额度每月免费渲4图,注册送40无门槛券,渲染低至0.07元/张.'
        />
        <BannerContainer>
          <img
            style={{ cursor: 'pointer' }}
            src={playIconSrc}
            onMouseEnter={() => {
              this.HoverPlayIcon(true)
            }}
            onMouseLeave={() => {
              this.HoverPlayIcon(false)
            }}
            alt='play'
            onClick={() => {
              this.ShowVideo()
            }}
          ></img>
          <BannerTitle>
            <span>效果图渲染 </span>
            <span>一键提交免费渲染</span>
          </BannerTitle>
          <BannerMessageText>
            效果图新用户注册可领取<BannerMessageCoupon>超45元新手大礼包</BannerMessageCoupon>
          </BannerMessageText>
          <div className='PhoneComboBox'>
            <PhoneCombo registerText='立即体验' />
          </div>
          <BannerSupportText>支持3ds Max | 客户端支持windows 7及以上</BannerSupportText>
          <AdvertiseBanner
            className='pc'
            src={data.allContentfulXgtBanner.edges[0].node.image.fluid.src}
            alt='picture on sale'
            onClick={() => window.open(data.allContentfulXgtBanner.edges[0].node.link)}
          />
          <Billboard className='bg_pc' fluid={data.banner.childImageSharp.fluid} />
          <Billboard className='bg_mb' fluid={data.mb.childImageSharp.fluid} />
        </BannerContainer>
        <AdvertiseBanner
          className='mb'
          src={data.allContentfulXgtBanner.edges[0].node.imageMobile.fluid.src}
          alt='picture on sale'
          onClick={() => window.open(data.allContentfulXgtBanner.edges[0].node.link)}
        />
        <AdvantageContainer lighter>
          <AdvantageTitle>效果图会员大回馈，尽享三重礼</AdvantageTitle>
          <Wrapper>
            <Row className='three-gift'>
              <Column xs='4' lg='4'>
                <IconGiftBox>
                  <FreeFourIcon />
                  <p>会员期间每月免单</p>
                  <p>4张图（不限金额）</p>
                </IconGiftBox>
              </Column>
              <Column xs='4' lg='4'>
                <IconGiftBox>
                  <LimitTwenty />
                  <p>会员期间单张</p>
                  <p>渲染20元封顶</p>
                </IconGiftBox>
              </Column>
              <Column xs='4' lg='4'>
                <IconGiftBox>
                  <HalfOffIcon />
                  <p>会员期间</p>
                  <p>渲染享0.03元/分钟</p>
                </IconGiftBox>
              </Column>
            </Row>
            <Button
              as='a'
              className='become-member'
              href='//account.renderbus.com/center/user/topUp?activeName=picture-vip-item'
              target='_blank'
              rel='nofollow'
            >
              立即成为会员
            </Button>
          </Wrapper>
        </AdvantageContainer>
        <AdvantageContainer>
          <AdvantageTitle>效果图客户端简单易用</AdvantageTitle>
          <Wrapper>
            <Row className='simple-usage'>
              <Column xs='6' lg='3'>
                <IconBox>
                  <AdvantageIcon1 />
                  <p>一键发布作业</p>
                </IconBox>
              </Column>
              <Column xs='6' lg='3'>
                <IconBox>
                  <AdvantageIcon2 />
                  <p>素材自动高速上传</p>
                </IconBox>
              </Column>
              <Column xs='6' lg='3'>
                <IconBox>
                  <AdvantageIcon3 />
                  <p>多作业同时渲染</p>
                </IconBox>
              </Column>
              <Column xs='6' lg='3'>
                <IconBox>
                  <AdvantageIcon4 />
                  <p>自动回传至本地</p>
                </IconBox>
              </Column>
            </Row>
          </Wrapper>
        </AdvantageContainer>
        <LighterBg>
          <Wrapper>
            <InfoContainer>
              <InfoImg src={info1} alt='效果图渲染实时进度'></InfoImg>
              <InfoContent position='right'>
                <InfoTitle>实时查看渲染进度</InfoTitle>
                <InfoSubTitle>
                  边制作边提交，3dsMax内插件一步提交作业，自动上传下载。针对渲染中的作业，点击“查看”即可看到光子、成图的实时渲染进度，不出图不收费。
                </InfoSubTitle>
                <div>
                  <IconInlineBox>
                    <RealTimeIcon1 />
                    <p>边制作边提交</p>
                  </IconInlineBox>
                  <IconInlineBox>
                    <RealTimeIcon2 />
                    <p>渲染时长预估</p>
                  </IconInlineBox>
                  <IconInlineBox>
                    <RealTimeIcon3 />
                    <p>实时查看进度</p>
                  </IconInlineBox>
                </div>
                <Button
                  as='a'
                  href='https://www.renderbus.com/download.html'
                  target='_blank'
                  color='primary'
                  variant='outlined'
                  rel='nofollow'
                >
                  立即体验
                </Button>
              </InfoContent>
            </InfoContainer>
          </Wrapper>
        </LighterBg>
        <Wrapper>
          <InfoContainer>
            <InfoContent position='left'>
              <InfoTitle>多机同时渲染，渲染速度提升10倍</InfoTitle>
              <InfoSubTitle>
                默认多相机多任务多机同时渲染，极速出图，极大提高渲染效率，场景一键优化辅助工具，省心省时更省钱。
              </InfoSubTitle>
              <div>
                <IconInlineBox>
                  <FasterIcon1 />
                  <p>512线程同时渲染</p>
                </IconInlineBox>
                <IconInlineBox>
                  <FasterIcon2 />
                  <p>极速出图</p>
                </IconInlineBox>
                <IconInlineBox>
                  <FasterIcon3 />
                  <p>自动检查缺贴图</p>
                </IconInlineBox>
              </div>
              <Button
                as='a'
                href='https://www.renderbus.com/download.html'
                target='_blank'
                color='primary'
                variant='outlined'
                rel='nofollow'
              >
                立即体验
              </Button>
            </InfoContent>
            <InfoImg src={info2} alt='多机渲染效果图'></InfoImg>
          </InfoContainer>
        </Wrapper>
        <LighterBg>
          <Wrapper>
            <InfoContainer>
              <InfoImg src={info3} alt='效果图渲染免费'></InfoImg>
              <InfoContent position='right'>
                <InfoTitle>海量丰富素材库，登录即可免费下载</InfoTitle>
                <div>
                  <IconInlineBox>
                    <MaterialIcon1 />
                    <p>免费下载</p>
                  </IconInlineBox>
                  <IconInlineBox>
                    <MaterialIcon2 />
                    <p>精选素材</p>
                  </IconInlineBox>
                  <IconInlineBox>
                    <MaterialIcon3 />
                    <p>高效赋能</p>
                  </IconInlineBox>
                </div>
                <Button
                  as='a'
                  href='https://www.renderbus.com/download.html'
                  target='_blank'
                  color='primary'
                  variant='outlined'
                  rel='nofollow'
                >
                  立即体验
                </Button>
              </InfoContent>
            </InfoContainer>
          </Wrapper>
        </LighterBg>
        <AdvantageContainer>
          <AdvantageTitle>效果图支持的软件和渲染器</AdvantageTitle>
          <Wrapper>
            <CompanyIcons>
              <CompanyIconBox>
                <IconBall>
                  <img src={companyIcon1} alt='支持3ds Max 支持客户端提交' />
                </IconBall>
                <p>3ds Max 支持客户端提交</p>
              </CompanyIconBox>
              <CompanyIconBox>
                <IconBall>
                  <img src={companyIcon7} alt='V-Ray 支持客户端提交' />
                </IconBall>
                <p>V-Ray 支持客户端提交</p>
              </CompanyIconBox>
              <CompanyIconBox>
                <IconBall>
                  <img src={companyIcon6} alt='Corona 支持客户端提交' />
                </IconBall>
                <p>Corona 支持客户端提交</p>
              </CompanyIconBox>
              <CompanyIconBox
                as='a'
                href='https://wpa.b.qq.com/cgi/wpa.php?ln=2&uin=4000034560'
                target='_blank'
              >
                <IconBall>
                  <img src={companyIcon5} alt='更多文件免费渲染' />
                </IconBall>
                <p>更多请咨询</p>
              </CompanyIconBox>
            </CompanyIcons>
          </Wrapper>
        </AdvantageContainer>
        <AdvantageContainer lighter={true}>
          <AdvantageTitle>效果图渲染代理招募</AdvantageTitle>
          <Wrapper>
            <Row>
              <Column xs='4'>
                <AgentBox>
                  <AgentTitle>设计资源平台</AgentTitle>
                  <AgentContent>
                    无论您提供的是行业模型、素材、软件、资讯、论坛，还是行业交流、交易、云设计等服务或平台，只要您拥有海量的CG行业用户，瑞云将为您和用户之间搭建桥梁，提供高性价比的渲染服务，实现资源利用率最大化。
                  </AgentContent>
                  <AgentButton
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                      this.joinUs('设计资源平台')
                    }}
                  >
                    立即加入
                  </AgentButton>
                  <AgentIcon>
                    <AgentIcon1 />
                  </AgentIcon>
                </AgentBox>
              </Column>
              <Column xs='4'>
                <AgentBox>
                  <AgentTitle>教育培训机构</AgentTitle>
                  <AgentContent>
                    随着视觉可视化行业的发展，无论是高校还是培训机构，都投入了较大的资源，培养了一批又一批的行业人才，瑞云愿意与各机构一起，为行业提供教育扶持和帮助以及技术支持，共同提升渲染效率，提高产出。
                  </AgentContent>
                  <AgentButton
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                      this.joinUs('教育培训机构')
                    }}
                  >
                    立即加入
                  </AgentButton>
                  <AgentIcon>
                    <AgentIcon2 />
                  </AgentIcon>
                </AgentBox>
              </Column>
              <Column xs='4'>
                <AgentBox>
                  <AgentTitle>行业意见领袖</AgentTitle>
                  <AgentContent>
                    如果您是行业资深从业者或网红KOL，拥有广泛的行业人脉资源或海量粉丝，那么，携手瑞云为粉丝谋福利刻不容缓，赶紧申请加入我们吧。
                  </AgentContent>
                  <AgentButton
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                      this.joinUs('行业意见领袖')
                    }}
                  >
                    立即加入
                  </AgentButton>
                  <AgentIcon>
                    <AgentIcon3 />
                  </AgentIcon>
                </AgentBox>
              </Column>
            </Row>
          </Wrapper>
        </AdvantageContainer>
        <AdvantageContainer hideSMDown={true}>
          <H1 as='h2' className='header'>
            云渲染集群搭建
          </H1>
          <Wrapper>
            <Row>
              <Column xs='3'>
                <BiggerIconBox>
                  <BiggerIcon1 />
                  <BiggerIconTitle>国际化渲染服务</BiggerIconTitle>
                  <BiggerIconContent>
                    瑞云渲染被誉为中国云渲染的先行者，参与了全球众多知名影视特效、动画和效果图等作品的渲染，深受国内外CG制作用户的欢迎。
                  </BiggerIconContent>
                </BiggerIconBox>
              </Column>
              <Column xs='3'>
                <BiggerIconBox>
                  <BiggerIcon2 />
                  <BiggerIconTitle>专业的技术支持</BiggerIconTitle>
                  <BiggerIconContent>
                    瑞云渲染在CG渲染优化、高性能计算等方面投入了大量的研究资源，拥有多项知识产权，为全球影视、视觉设计等领域提供了强大易用的云渲染服务。
                  </BiggerIconContent>
                </BiggerIconBox>
              </Column>
              <Column xs='3'>
                <BiggerIconBox>
                  <BiggerIcon3 />
                  <BiggerIconTitle>海量计算资源管理</BiggerIconTitle>
                  <BiggerIconContent>
                    广泛支持主流软件和插件，使用瑞云渲染集群管理软件，可以帮助工作室轻松掌握先进渲染农场技术，实现海量计算资源管理。
                  </BiggerIconContent>
                </BiggerIconBox>
              </Column>
              <Column xs='3'>
                <BiggerIconBox>
                  <BiggerIcon4 />
                  <BiggerIconTitle>有效降低渲染成本</BiggerIconTitle>
                  <BiggerIconContent>
                    使用瑞云渲染集群管理软件，可以有效降低渲染成本，提升制作效率，充分释放创意，为工作室带来价值。
                  </BiggerIconContent>
                </BiggerIconBox>
              </Column>
            </Row>
          </Wrapper>
        </AdvantageContainer>
        <RegisterBanner isPicture='true' />
        <Modal
          visible={showModal}
          onClose={e => {
            this.CloseModal(e)
          }}
          title='代理申请'
          applyTitle={true}
        >
          <JoinUsForm
            onClose={e => {
              this.CloseModal(e)
            }}
            agentType={agentType}
          ></JoinUsForm>
        </Modal>
        <Modal
          simpleHeader
          bodyStyle={{ padding: '0' }}
          visible={showVideo}
          onClose={e => {
            this.CloseVideo(e)
          }}
          darkTheme={true}
        >
          <video
            src='https://videoread.oss-cn-beijing.aliyuncs.com/rending.mp4'
            allowFullScreen={true}
            controls={true}
            autoPlay={true}
            title='效果图渲染'
            style={{ width: '800px' }}
            ref={this.setVideoRef}
          />
        </Modal>
      </Layout>
    )
  }
}

export default ArchitecturalRendering

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/architectural-rendering/banner.jpg/" }) {
      ...fluidImage
    }
    mb: file(relativePath: { regex: "/architectural-rendering/banner_mb.png/" }) {
      ...fluidImage
    }
    rowBanner: file(relativePath: { eq: "51banner/bg1.png" }) {
      ...fluidImage
    }
    DoubleTwelveBanner: file(relativePath: { regex: "/xgt1212/xgt-double-twelve-banner.png/" }) {
      ...fluidImage
    }
    DoubleTwelveMobileBanner: file(
      relativePath: { regex: "/promotion/xgt-double-twelve-activity.png/" }
    ) {
      ...fluidImage
    }
    allContentfulXgtBanner(limit: 1) {
      edges {
        node {
          imageMobile {
            fluid(quality: 100, maxWidth: 2000) {
              src
            }
          }
          image {
            fluid(quality: 100, maxWidth: 2000) {
              src
            }
          }
          link
        }
      }
    }
  }
`
